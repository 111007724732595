import { SendSmsDTO, VerificationCodeDTO } from '../types';
import axios, { endpoints } from '../utils/axios';

export async function sendPhoneVerificationCode(payload: SendSmsDTO): Promise<void> {
  if (!payload.phone && !payload.email) {
    throw new Error('Phone or email must be provided to send SMS');
  }

  await axios.post(endpoints.auth.phoneVerification, payload);
}

export async function verifyPhoneCode(payload: VerificationCodeDTO): Promise<void> {
  if (!payload.phone && !payload.email) {
    throw new Error('Phone or email must be provided to send SMS');
  }

  await axios.post(endpoints.auth.phoneVerifyCode, payload);
}
