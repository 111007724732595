import { getMessaging, Messaging, isSupported, getToken } from 'firebase/messaging';
import { User } from '../types';
import axios, { endpoints } from './axios';

export async function handleDeviceToken(user: User) {
  // User has disabled all notifications
  if (user?.notificationPreferences?.disableAll) {
    return;
  }

  let messaging: Messaging;
  const isBrowserSupported = await isSupported();
  if (!isBrowserSupported) {
    return;
  }

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', (event) => {
      // TODO: Implement foreground notification
    });
  }

  try {
    messaging = getMessaging();

    const status = await Notification.requestPermission();

    if (status === 'denied') {
      return;
    }

    const fcmToken = await getToken(messaging);

    if (!user?.deviceToken || fcmToken !== user?.deviceToken?.token) {
      // Token has been refreshed or didn't existed before
      await axios.post(endpoints.user.update, { deviceToken: fcmToken });
    }
  } catch (e) {
    console.error(e);
  }
}
