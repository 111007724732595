'use client';

import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PropsWithChildren } from 'react';
import { POSTHOG } from '../config-global';

const isProdEnvironment = typeof window !== 'undefined' && window.location.host === 'trybe.fm';
if (isProdEnvironment && POSTHOG.publicKey) {
  posthog.init(POSTHOG.publicKey, {
    api_host: POSTHOG.host,
    capture_pageview: false, // Disable automatic pageview capture, as we capture manually
  });
}
export function PHProvider({ children }: PropsWithChildren) {
  if (isProdEnvironment) {
    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
  }
  return <>{children}</>;
}
