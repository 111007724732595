import axios, { AxiosRequestConfig } from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

export const post = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.post(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  user: {
    update: '/users/me/update-profile',
    avatar: '/avatars',
    changePassword: '/users/me/change-password',
    exists: '/users/exists',
  },
  auth: {
    me: '/users/me',
    profilePictures: '/me/pictures',
    updateInfo: '/users/me/update-profile',
    phoneVerification: '/users/phone-verification',
    phoneVerifyCode: '/users/check-verification-code',
    login: '/auth/login',
    register: '/auth/register',
    registerInfo: '/auth/signup',
    updatePictures: `/users/me/pictures`,
    resetPassword: 'auth/reset-password',
    verifyMail: 'auth/verify-mail',
    verifyResetToken: '/auth/verify-reset-token',
  },
  organization: {
    getPage: (name: string) => `/organizations/${name}/page`,
  },
  subs: '/subs',
  event: {
    list: '/events',
    live: '/events/live',
    count: '/events/count',
    detail: (id: string) => `/events/${id}`,
    update: (id: string) => `/events/${id}`,
    detailPublic: (id: string) => `/events/${id}/public`,
    create: '/events',
    submit: '/submitted-events',
    updateCover: (id: string) => `/events/${id}/cover`, // PUT
    deleteCover: (id: string) => `/events/${id}/cover`, // DELETE
    updatePictures: (id: string) => `/events/${id}/pictures`, // PUT
    attended: '/events/me/attended',
    submitted: '/events/me/submitted',
    subscribedEvents: '/subscribed_events',
  },
  eventRequests: {
    sendRequest: (id: string) => `/events/${id}/event_requests`,
  },
  connect: {
    list: (id: string) => `/connect/${id}/attendees`,
    likes: '/connect/requests',
    likesCount: '/connect/requests/count',
    request: (id: string) => `/connect/${id}/request`,
    participate: (id: string) => `/events/${id}/participants`,
    updateAttendance: (id: string) => `/events/${id}/participants`,
    channel: (id: string) => `/connect/matches/${id}`,
    matches: {
      getMatches: '/connect/matches',
      showAvatar: (id: string) => `/connect/matches/${id}/reveal`,
      unMatch: (id: string) => `/connect/matches/${id}/unmatch`,
    },
  },
  orders: {
    processOrder: '/orders',
    getById: (orderId: string) => `/orders/${orderId}`,
    getByCartId: (cartId: string) => `/orders/carts/${cartId}`,
    processFreeOrder: (cartId: string) => `/orders/carts/${cartId}`,
  },
  cities: {
    findAll: '/cities',
  },
  cart: {
    createCart: (id: string) => `/events/${id}/carts`,
    getCart: (cartId: string) => `/carts/${cartId}`,
    updateCart: (cartId: string) => `/carts/${cartId}`,
  },
  contact: (orgId: number) => `/contacts/organizations/${orgId}`,
  promoCode: {
    findByNameAndEventId: (eventId: string, name: string) =>
      `/events/${eventId}/promocodes/${name}`,
  },
  tickets: {
    findOne: (id: string) => `/tickets/${id}`,
    getByEventId: (id: string) => `/tickets/events/${id}`,
    getTicketsGroupedByEvent: `/events/me/tickets`,
  },
};
